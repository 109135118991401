const state = {
    module: '',
	page: ''
}

const mutations = {
	SET_STATE: (state, data) => {
		state.module = data.module
		state.page = data.page
	}
}

const actions = {
	setState({ commit }, data) {
		commit('SET_STATE', data)
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}