import { getMenus, updateMenu } from '@/api/epanel/menu'

const state = {
	items: [],
	headers: [],
	pagination: {},
	menu: {}
}

const mutations = {
	SET_STATE: (state, data) => {
		state.items = data.items
		state.headers = data.headers
		state.pagination = data.pagination
	},
    SET_MENU: (state, data) => {
		state.menu = data
	},
}

const actions = {
	getMenus({ commit }, data) {
		return new Promise((resolve, reject) => {
			getMenus(data).then(response => {
				commit('SET_MENU', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateMenu({ commit }, data) {
		return new Promise((resolve, reject) => {
            updateMenu(data.id, data.object).then(response => {
                commit('SET_MENU', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}