import { get, post, put } from '@/utils/request'

export function getReportManpower(data) {
	return get('api/epanel/report/manpower/', data)
}

export function getReportManpowerClient(data) {
	return get('api/epanel/report/manpower/client/', data)
}

export function getReportSalesBrand(data) {
    return get('api/epanel/report/sales/brand/', data)
}

export function getReportSalesWeekly(){
    return get('api/epanel/report/sales/weekly/')
}

export function getReportCampaignMonthly(data) {
    return get('api/epanel/report/campaign/monthly/', data)
}

export function updateReportCampaignMonthly(data) {
    return post('api/epanel/report/campaign/monthly/', data)
}

export function generateReportCampaignMonthly(data) {
    return post('api/epanel/report/campaign/monthly/generate/', data)
}

export function getReportCampaignOutlet(data) {
    return get('api/epanel/report/campaign/outlet/', data)
}

export function updateReportCampaignOutlet(data) {
    return post('api/epanel/report/campaign/outlet/', data)
}

export function generateReportCampaignOutlet(data) {
    return post('api/epanel/report/campaign/outlet/generate/', data)
}

export function getReportTimesheet(data) {
    return get('api/epanel/report/timesheet/', data)
}

export function updateReportTimesheet(id, data) {
    return put('api/epanel/report/timesheet/' + id + "/", data)
}

export function getReportTimesheetInfo(data) {
    return get('api/epanel/report/timesheet/info/', data)
}

export function updateReportTimesheetInfo(data){
    return post('api/epanel/report/timesheet/info/', data)
}

export function getReportAttendancePhotos(data) {
    return get('api/epanel/report/activity/attendance/photos/', data)
}

export function getReportAttendancePhotoInfo(data) {
    return get('api/epanel/report/activity/attendance/photos/info/', data)
}

export function getReportOutletVisitingFeedbacks(data) {
    return get('api/epanel/report/activity/visiting/', data)
}

export function getReportOutletVisitingFeedbackInfo(data) {
    return get('api/epanel/report/activity/visiting/info/', data)
}

export function getPromoterListing() {
    return get('api/epanel/report/promoter/')
}

