import { getShiftDates, getShiftPromoters, getShiftPromoter, getShifts, createShift, updateShift, deleteShifts, publishShifts, deployShifts, getNonCPFPayrollShiftByDate } from '@/api/epanel/shift'

const state = {
	items: [],
	headers: [],
	pagination: {},
	shift: {}
}

const mutations = {
	SET_STATE: (state, data) => {
		state.items = data.items
		state.headers = data.headers
		state.pagination = data.pagination
	},
    SET_SHIFT: (state, data) => {
        state.shift = data
    }
}

const actions = {
	getShiftDates({ commit }, data) {
		return new Promise((resolve, reject) => {
			getShiftDates(data).then(response => {
				commit('SET_SHIFT', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
    getShiftPromoters({ commit }, data) {
        return new Promise((resolve, reject) => {
            getShiftPromoters(data).then(response => {
                commit('SET_SHIFT', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getShiftPromoter({ commit }, data) {
        return new Promise((resolve, reject) => {
            getShiftPromoter(data.id).then(response => {
                commit('SET_SHIFT', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
	getShifts({ commit }, data) {
		return new Promise((resolve, reject) => {
			getShifts(data).then(response => {
				commit('SET_SHIFT', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	createShift({ commit }, data) {
		return new Promise((resolve, reject) => {
            createShift(data).then(response => {
                commit('SET_SHIFT', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateShift({ commit }, data) {
		return new Promise((resolve, reject) => {
            updateShift(data.id, data.object).then(response => {
                commit('SET_SHIFT', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	deleteShifts({ commit }, data) {
		return new Promise((resolve, reject) => {
            deleteShifts(data.object).then(response => {
                commit('SET_SHIFT', {})
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
    publishShifts({ commit }, data) {
        return new Promise((resolve, reject) => {
            publishShifts(data.object).then(response => {
                commit('SET_SHIFT', {})
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    deployShifts({ commit }, data) {
        return new Promise((resolve, reject) => {
            deployShifts(data.object).then(response => {
                commit('SET_SHIFT', {})
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
	getNonCPFPayrollShiftByDate({ commit }, data) {
		return new Promise((resolve, reject) => {
			getNonCPFPayrollShiftByDate(data).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}