import { get, post, put, remove } from '@/utils/request'

export function getBanks() {
	return get('api/epanel/setting/banks/')
}

export function getBank(id) {
    return get('api/epanel/setting/banks/' + id + '/')
}

export function createBank(data) {
	return post('api/epanel/setting/banks/', data)
}

export function updateBank(id, data) {
    return put('api/epanel/setting/banks/' + id + '/', data)
}

export function deleteBank(id) {
    return remove('api/epanel/setting/banks/' + id + '/')
}

export function getBankBranches(data) {
	return get('api/epanel/setting/banks/branches/', data)
}

export function createBankBranch(data) {
	return post('api/epanel/setting/banks/branches/', data)
}

export function updateBankBranch(id, data) {
    return put('api/epanel/setting/banks/branches/' + id + '/', data)
}

export function deleteBankBranch(id) {
    return remove('api/epanel/setting/banks/branches/' + id + '/')
}