import { get, post, put } from '@/utils/request'

export function getAdmins(params) {
	return get('api/epanel/account/admins/', params)
}

export function createAdmin(data) {
    return post('api/epanel/account/admins/', data)
}

export function updateAdmin(id, data, name) {
    return put('api/epanel/account/admins/' + id + '/' + name + '/', data)
}

export function getAdminPermissions(id, params) {
    return get('api/epanel/account/admins/' + id + '/permissions/', params)
}

export function updateAdminPermissions(id, data) {
    return post('api/epanel/account/admins/' + id + '/permissions/', data)
}
