import {getReportManpower, getReportManpowerClient, getReportSalesBrand, getReportSalesWeekly,
        getReportCampaignMonthly, updateReportCampaignMonthly, generateReportCampaignMonthly,
        getReportCampaignOutlet, updateReportCampaignOutlet, generateReportCampaignOutlet,
        getReportTimesheet, getReportTimesheetInfo, updateReportTimesheetInfo, updateReportTimesheet,
        getReportAttendancePhotos, getReportAttendancePhotoInfo, getReportOutletVisitingFeedbacks, getReportOutletVisitingFeedbackInfo,
		getPromoterListing } from '@/api/epanel/report'

const state = {
	items: [],
	headers: [],
	pagination: {},
	chart: {}
}

const mutations = {
	SET_STATE: (state, data) => {
		state.items = data.items
		state.headers = data.headers
		state.pagination = data.pagination
	},
}

const actions = {
	getReportManpower({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getReportManpower(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getReportManpowerClient({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getReportManpowerClient(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getReportSalesBrand({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getReportSalesBrand(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
    getReportSalesWeekly({ commit }, paramsData) {
        return new Promise((resolve, reject) => {
            getReportSalesWeekly(paramsData).then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
	getReportCampaignMonthly({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getReportCampaignMonthly(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateReportCampaignMonthly({ commit }, data) {
		return new Promise((resolve, reject) => {
			updateReportCampaignMonthly(data).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	generateReportCampaignMonthly({ commit }, data) {
		return new Promise((resolve, reject) => {
			generateReportCampaignMonthly(data).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getReportCampaignOutlet({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getReportCampaignOutlet(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateReportCampaignOutlet({ commit }, data) {
		return new Promise((resolve, reject) => {
			updateReportCampaignOutlet(data).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	generateReportCampaignOutlet({ commit }, data) {
		return new Promise((resolve, reject) => {
			generateReportCampaignOutlet(data).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getReportTimesheet({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getReportTimesheet(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getReportTimesheetInfo({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getReportTimesheetInfo(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateReportTimesheetInfo({ commit }, data) {
		return new Promise((resolve, reject) => {
			updateReportTimesheetInfo(data).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateReportTimesheet({ commit }, data) {
		return new Promise((resolve, reject) => {
			updateReportTimesheet(data.id, data.object).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getReportAttendancePhotos({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getReportAttendancePhotos(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getReportAttendancePhotoInfo({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getReportAttendancePhotoInfo(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getReportOutletVisitingFeedbacks({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getReportOutletVisitingFeedbacks(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getReportOutletVisitingFeedbackInfo({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getReportOutletVisitingFeedbackInfo(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getPromoterListing({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getPromoterListing(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}