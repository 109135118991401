import AppLayout from '@/views/mobile/layout/AppLayout'


const router = {
    path: '/',
	component: AppLayout,
	meta: {
		title: 'mobile'
	},
	children: [
        {
            path: 'today',
            name: 'mobile-today',
            component: () => import('@/views/mobile/today/TodayShiftList'),
            meta: { title: 'Today', group: 'today', page: 'today', level: 1, is_cached: true }
        },
        {
            path: 'today/shift',
            name: 'mobile-today-shift',
            component: () => import('@/views/mobile/shift/ShiftDashboard'),
            meta: { title: 'Today\'s Shift', group: 'today', page: 'shift', level: 2, is_cached: false, back_url: '/today' }
        },
        {
            path: 'upcoming',
            name: 'mobile-upcoming',
            component: () => import('@/views/mobile/upcoming/UpcomingShiftList'),
            meta: { title: 'Upcoming', group: 'upcoming', page: 'upcoming', level: 1, is_cached: true }
        },
        {
            path: 'upcoming/shift',
            name: 'mobile-upcoming-shift',
            component: () => import('@/views/mobile/shift/ShiftDashboard'),
            meta: { title: 'Upcoming\'s Shift', group: 'upcoming', page: 'shift', level: 2, is_cached: false, back_url: '/upcoming' }
        },
        {
            path: 'me',
            name: 'mobile-my-account',
            component: () => import('@/views/mobile/account/MyAccount'),
            meta: { title: 'My Account', group: 'me', page: 'me', level: 1, is_cached: false }
        },
        {
            path: 'me/profile',
            name: 'mobile-my-profile',
            component: () => import('@/views/mobile/account/profile/MyProfile'),
            meta: { title: 'My Profile', group: 'me', page: 'profile', level: 2, is_cached: false }
        },
        {
            path: 'me/payslip',
            name: 'mobile-my-payslips',
            component: () => import('@/views/mobile/account/payslip/MyPayslipList'),
            meta: { title: 'My Payslip', group: 'me', page: 'payslip', level: 3, is_cached: false }
        },
        {
            path: 'me/worked-history',
            name: 'mobile-worked-history',
            component: () => import('@/views/mobile/account/shift/MyWorkedShiftList'),
            meta: { title: 'My Worked History', group: 'me', page: 'worked-shift', level: 2, is_cached: true, back_url: '/me' }
        },
        {
            path: 'me/worked-history/shift',
            name: 'mobile-worked-shift',
            component: () => import('@/views/mobile/shift/ShiftDashboard'),
            meta: { title: 'Worked\'s Shift', group: 'me', page: 'shift', level: 3, is_cached: false, back_url: '/me/worked-history' }
        },
	],

}

export default router