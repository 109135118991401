

export function parseUrlParams(url) {
    return new URL(url).searchParams
}

export function redirectPathTo(router, path, params=''){
    router.push({ path: path, params: params})
}

export function redirectNameTo(router, name, params=''){
    router.push({ name: name, params: params})
}