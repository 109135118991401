import { getDashboard, getApplications, getApplication, updateApplication, getActivationDashboard, updateActivationShift,
         getDeploymentDashboard, getOutletVisitingDashboard, updateOutletVisitingShift,
         getMyOutletVisitingDashboard, getMyOutletVisitingInfo, createOutletVisitingFeedback, getDashboardPromoterList, getKivPromoterDashboard, updateKivPromoterDashboard } from '@/api/epanel/dashboard'

const state = {
	items: [],
	headers: [],
	pagination: {},
	client: {}
}

const mutations = {
	SET_STATE: (state, data) => {
		state.items = data.items
		state.headers = data.headers
		state.pagination = data.pagination
	},
}

const actions = {
	getDashboard({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getDashboard(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getApplications({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getApplications(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getApplication({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
            getApplication(paramsData.id, paramsData.filters).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateApplication({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
            updateApplication(paramsData.id, paramsData.object).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
    getActivationDashboard({ commit }, paramsData) {
        return new Promise((resolve, reject) => {
            getActivationDashboard(paramsData).then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getKivPromoterDashboard({ commit }, paramsData) {
        return new Promise((resolve, reject) => {
            getKivPromoterDashboard(paramsData).then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    updateKivPromoterDashboard({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateKivPromoterDashboard(data).then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    updateActivationShift({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateActivationShift(data).then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getDeploymentDashboard({ commit }, paramsData) {
        return new Promise((resolve, reject) => {
            getDeploymentDashboard(paramsData).then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getOutletVisitingDashboard({ commit }, paramsData) {
        return new Promise((resolve, reject) => {
            getOutletVisitingDashboard(paramsData).then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    updateOutletVisitingShift({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateOutletVisitingShift(data).then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getMyOutletVisitingDashboard({ commit }, paramsData) {
        return new Promise((resolve, reject) => {
            getMyOutletVisitingDashboard(paramsData).then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getMyOutletVisitingInfo({ commit }, paramsData) {
        return new Promise((resolve, reject) => {
            getMyOutletVisitingInfo(paramsData.id).then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    createOutletVisitingFeedback({ commit }, data) {
        return new Promise((resolve, reject) => {
            createOutletVisitingFeedback(data.id, data.object).then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getDashboardPromoterList({ commit }, paramsData) {
        return new Promise((resolve, reject) => {
            getDashboardPromoterList(paramsData).then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}