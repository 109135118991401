import { post, put } from '@/utils/request'

export function login(data) {
	return post('api/auth/login/', data)
}

export function logout() {
	return post('api/auth/logout/')
}

export function updateProfile(data) {
	return put('api/auth/profile/', data)
}

export function updatePassword(data) {
	return put('api/auth/password/', data)
}