import { getClients, createClient, updateClient } from '@/api/epanel/client'

const state = {
	items: [],
	headers: [],
	pagination: {},
	client: {}
}

const mutations = {
	SET_STATE: (state, data) => {
		state.items = data.items
		state.headers = data.headers
		state.pagination = data.pagination
	},
    SET_CLIENT: (state, data) => {
		state.client = data
	},
}

const actions = {
	getClients({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getClients(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	createClient({ commit }, data) {
		return new Promise((resolve, reject) => {
            createClient(data).then(response => {
                commit('SET_CLIENT', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateClient({ commit }, data) {
		return new Promise((resolve, reject) => {
            updateClient(data.id, data.object).then(response => {
                commit('SET_CLIENT', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateClientAccountPassword({ commit }, data) {
		return new Promise((resolve, reject) => {
            updateClient(data.url, data.object).then(response => {
                commit('SET_CLIENT', {})
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}