<template>
	<div>
		<transition name="fade-transform" mode="out-in">
			<keep-alive v-if="$route.meta.is_cached">
				<router-view></router-view>
			</keep-alive>
            <router-view v-if="!$route.meta.is_cached"></router-view>
		</transition>
	</div>
</template>


<script>

export default {
	name: 'EmptyLayout'
}

</script>