import { get, post, put, remove } from '@/utils/request'

export function getChains(params) {
	return get('api/epanel/store/chains/', params)
}

export function createChain(data) {
    return post('api/epanel/store/chains/', data)
}

export function updateChain(id, data) {
    return put('api/epanel/store/chains/' + id + '/', data)
}

export function deleteChain(id) {
    return remove('api/epanel/store/chains/' + id + '/')
}
