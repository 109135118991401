import { get, post, put, remove } from '@/utils/request'


export function getShiftDates(data) {
	return get('api/epanel/shifts/dates/', data)
}

export function getShiftPromoters(data) {
	return get('api/epanel/shifts/promoters/', data)
}

export function getShiftPromoter(id) {
    return get('api/epanel/shifts/promoters/' + id + '/')
}

export function getShifts(data) {
	return get('api/epanel/shifts/', data)
}

export function createShift(data) {
    return post('api/epanel/shifts/', data)
}

export function retrieveShift(id) {
    return get('api/epanel/shift/' + id + '/')
}

export function updateShift(id, data) {
    return put('api/epanel/shifts/' + id + '/', data)
}

export function deleteShifts(data) {
    return remove('api/epanel/shifts/', data)
}

export function publishShifts(data) {
    return put('api/epanel/shifts/publish/', data)
}

export function deployShifts(data) {
    return put('api/epanel/shifts/deploy/', data)
}

export function getNonCPFPayrollShiftByDate(data) {
    return get('api/epanel/shifts/non-cpf/', data)
}



