import { getAccount, checkAccount, createAccount, createProfilePicture, getProfile } from '@/api/mobile/account'

const state = {
	object: {}
}

const mutations = {
	SET_OBJECT: (state, data) => {
		state.object = data.object
	},

}

const actions = {
	getAccount({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getAccount(paramsData).then(response => {
				commit('SET_OBJECT', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	checkAccount({ commit }, data) {
		return new Promise((resolve, reject) => {
            checkAccount(data).then(response => {
                commit('SET_OBJECT', response.data)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	createAccount({ commit }, data) {
		return new Promise((resolve, reject) => {
            createAccount(data).then(response => {
                commit('SET_OBJECT', response.data)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
    createProfilePicture({ commit }, data) {
        return new Promise((resolve, reject) => {
            createProfilePicture(data).then(response => {
                commit('SET_OBJECT', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getProfile({ commit }) {
		return new Promise((resolve, reject) => {
			getProfile().then(response => {
				commit('SET_OBJECT', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}