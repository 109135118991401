<template>
	<div>
        <v-app>
            <v-main class="grey lighten-5">
                <transition :name="transitionName" :mode="mode" v-on:after-enter="afterEnter" v-on:after-leave="afterLeave">
                    <div v-if="isMobile()">
                        <keep-alive v-if="isCached()">
                            <router-view class="transition"></router-view>
                        </keep-alive>
                        <router-view v-if="!isCached()"></router-view>
                    </div>
                    <div v-if="!isMobile()">
                        <v-container fluid fill-height class="container px-6">
                            <v-layout align-center justify-center>
                                <v-card class="mx-auto rounded-lg elevation-0 bg-color text-center">
                                    <v-card-text class="text-h4">
                                        The blink app is used for mobile device only. <br>Please use mobile to login blink app. <br>Thank you!
                                    </v-card-text>
                                </v-card>
                            </v-layout>
                        </v-container>
                    </div>
                </transition>
            </v-main>
        </v-app>
	</div>
</template>


<script>

export default {
	name: 'AppLayout',
	data() {
        return {
            transitionName: '',
            mode: ''
        }
    },
    methods: {
        updateTransitionName: function(from, to) {
            const fromLevel = from['meta']['level'], toLevel = to['meta']['level']
            if(fromLevel === 1 && toLevel === 1){
                this.transitionName = ''
                this.mode = ''
            }else{
                this.transitionName = toLevel > fromLevel?'router-view':'router-view-back'
                this.mode = toLevel > fromLevel?'in-out':''
            }
        },
        afterEnter: function() {
            window.scrollTo(0, 0)
        },
        afterLeave: function() {
            this.transitionName = 'router-view'
            this.mode = 'in-out'
        },
        isCached: function() {
            return this.$route.meta.is_cached
        },
        isMobile: function(){
            return this.$vuetify.breakpoint.mobile
        }

    },
    watch: {
        '$route': function(to, from) {
            this.updateTransitionName(from, to)
        }
    }
}

</script>


<style scoped>
.transition {
    overflow: hidden;
}

.bg-color {
    background-color: #FAFAFA !important;
}

</style>
