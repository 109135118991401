import Layout from '@/views/epanel/layout/MainLayout.vue'
import EmptyLayout from '@/views/epanel/layout/EmptyLayout.vue'


const router = {
    path: '/epanel',
    component: Layout,
    meta: {
        title: 'epanel'
    },
    children: [{
            path: 'dashboard',
            component: EmptyLayout,
            children: [{
                    path: '',
                    component: () =>
                        import ('@/views/epanel/dashboard/DashboardHome'),
                    name: 'epanel-dashboard',
                    meta: { title: 'Dashboard', group: 'dashboard', page: 'dashboard-activation', has_menu: true, is_cached: false }
                },
                {
                    path: 'application',
                    component: () =>
                        import ('@/views/epanel/dashboard/account/PromoterApplicationList'),
                    name: 'dashboard-applications',
                    meta: { title: 'Promoter Applications', group: 'dashboard', page: 'dashboard-applications', has_menu: true, is_cached: false }
                },
                {
                    path: 'application/:id',
                    component: () =>
                        import ('@/views/epanel/dashboard/account/PromoterApplicationForm'),
                    name: 'dashboard-application',
                    meta: { title: 'Promoter Application Form', group: 'dashboard', page: 'dashboard-application', has_menu: false, is_cached: false }
                }
            ]
        },
        {
            path: 'account',
            component: EmptyLayout,
            children: [{
                    path: 'profile',
                    component: () =>
                        import ('@/views/epanel/account/profile/ProfileForm'),
                    name: 'account-profile',
                    meta: { title: 'My Profile', group: 'profile', page: 'account-profile', has_menu: true, is_cached: false }
                },
                {
                    path: 'admin',
                    component: () =>
                        import ('@/views/epanel/account/admin/AdminList'),
                    name: 'account-admins',
                    meta: { title: 'Admin Management', group: 'account', page: 'account-admins', has_menu: true, is_cached: true }
                },
                {
                    path: 'admin/:id/permission',
                    component: () =>
                        import ('@/views/epanel/account/admin/permission/PermissionList'),
                    name: 'account-admin-permissions',
                    meta: { title: 'Admin Permission Management', group: 'account', page: 'account-admin-permissions', has_menu: true, is_cached: false }
                },
                {
                    path: 'promoter',
                    component: () =>
                        import ('@/views/epanel/account/promoter/PromoterList'),
                    name: 'account-promoters',
                    meta: { title: 'Promoter Management', group: 'account', page: 'account-promoters', has_menu: true, is_cached: true }
                },
                {
                    path: 'promoter/add',
                    component: () =>
                        import ('@/views/epanel/account/promoter/PromoterCreateForm'),
                    name: 'account-promoter-add',
                    meta: { title: "Create Promoter Account", group: 'account', page: 'account-promoter-add', has_menu: false, is_cached: false }
                },
                {
                    path: 'promoter/edit/:id',
                    component: () =>
                        import ('@/views/epanel/account/promoter/PromoterForm'),
                    name: 'account-promoter-edit',
                    meta: { title: "Update Promoter Account", group: 'account', page: 'account-promoter-edit', has_menu: false, is_cached: false }
                },
                {
                    path: 'promoter/view/:id',
                    component: () =>
                        import ('@/views/epanel/account/promoter/PromoterForm'),
                    name: 'account-promoter-view',
                    meta: { title: "View Promoter Account", group: 'account', page: 'account-promoter-view', has_menu: false, is_cached: false }
                },
                {
                    path: 'role',
                    component: () =>
                        import ('@/views/epanel/account/role/RoleList'),
                    name: 'account-roles',
                    meta: { title: 'Role Management', group: 'account', page: 'account-roles', has_menu: true, is_cached: false }
                },
                {
                    path: 'role/:id/permission',
                    component: () =>
                        import ('@/views/epanel/account/role/permission/PermissionList'),
                    name: 'account-role-permissions',
                    meta: { title: 'Role Permission Management', group: 'account', page: 'account-role-permissions', has_menu: true, is_cached: false }
                },
                {
                    path: 'permission',
                    component: () =>
                        import ('@/views/epanel/account/permission/PermissionList'),
                    name: 'account-permissions',
                    meta: { title: 'Permission Management', group: 'account', page: 'account-permissions', has_menu: true, is_cached: true }
                },
                {
                    path: 'menu',
                    component: () =>
                        import ('@/views/epanel/account/menu/MenuList'),
                    name: 'account-menus',
                    meta: { title: 'Menu Management', group: 'account', page: 'account-menus', has_menu: true, is_cached: true }
                },
            ]
        },
        {
            path: 'store',
            component: EmptyLayout,
            children: [{
                    path: 'chain',
                    component: () =>
                        import ('@/views/epanel/store/chain/ChainList'),
                    name: 'chain-list',
                    meta: { title: 'Chain Management', group: 'store', page: 'chain', has_menu: true, is_cached: true }
                },
                {
                    path: 'chain/:id/outlet',
                    component: () =>
                        import ('@/views/epanel/store/chain/outlet/OutletList'),
                    name: 'chain-outlet-list',
                    meta: { title: "Chain Outlet Management", group: 'store', page: 'chain', has_menu: true, is_cached: false }
                },
                {
                    path: 'address',
                    component: () =>
                        import ('@/views/epanel/store/address/AddressList'),
                    name: 'address-list',
                    meta: { title: 'Address Management', group: 'store', page: 'address', has_menu: true, is_cached: true }
                },
                {
                    path: 'address/:id/outlet',
                    component: () =>
                        import ('@/views/epanel/store/address/outlet/OutletList'),
                    name: 'address-outlet-list',
                    meta: { title: "Address Outlet Management", group: 'store', page: 'address', has_menu: true, is_cached: false }
                }
            ]
        },
        {
            path: 'company',
            component: () =>
                import ('@/views/epanel/company/CompanyList'),
            name: 'company-list',
            meta: { title: 'Company Management', group: 'company', page: 'company', has_menu: true, is_cached: false }
        },
        {
            path: 'company/:id/bank',
            component: () =>
                import ('@/views/epanel/company/bank/CompanyBankList'),
            name: 'company-bank-list',
            meta: { title: 'Company Bank List', group: 'company', page: 'company-bank-list', has_menu: true, is_cached: false }
        },
        {
            path: 'client',
            component: () =>
                import ('@/views/epanel/client/ClientList'),
            name: 'client-list',
            meta: { title: 'Client Management', group: 'client', page: 'client', has_menu: true, is_cached: false }
        },
        {
            path: 'project',
            component: EmptyLayout,
            children: [{
                    path: 'brand',
                    component: () =>
                        import ('@/views/epanel/project/brand/ProjectList'),
                    name: 'brand-list',
                    meta: { title: 'Brand Management', group: 'project', page: 'brand-list', has_menu: true, is_cached: true }
                },
                {
                    path: 'brand/:id/info',
                    component: () =>
                        import ('@/views/epanel/project/brand/ProjectInfo'),
                    name: 'brand-info',
                    meta: { title: "Brand Info", group: 'project', page: 'brand-info', has_menu: true, is_cached: false }
                },
                {
                    path: 'brand/:id/deployment',
                    component: () =>
                        import ('@/views/epanel/project/deployment/DeploymentList'),
                    name: 'brand-deployment',
                    meta: { title: "Campaign Deployment", group: 'project', page: 'brand-deployment', has_menu: false, is_cached: true }
                },
                {
                    path: 'brand/:id/timesheet',
                    component: () =>
                        import ('@/views/epanel/project/timesheet/TimesheetList'),
                    name: 'brand-timesheet',
                    meta: { title: "Campaign Timesheet", group: 'project', page: 'brand-timesheet', has_menu: false, is_cached: false }
                },
                {
                    path: 'resource',
                    component: () =>
                        import ('@/views/epanel/project/resource/ProjectResourceList'),
                    name: 'project-resource-list',
                    meta: { title: 'Resource Management', group: 'project', page: 'project-resource-list', has_menu: true, is_cached: true }
                },
            ]
        },
        {
            path: 'resource',
            component: () =>
                import ('@/views/epanel/project/resource/ProjectResourceList'),
            name: 'brand-resource-list',
            meta: { title: 'Brand Resource Management', group: 'project', page: 'brand-resource', has_menu: true, is_cached: true }
        },
        {
            path: 'deployment/dashboard-deployment',
            component: () =>
                import ('@/views/epanel/dashboard/deployment/DashboardDeployment'),
            name: 'dashboard-deployment-board',
            meta: { title: 'Deployment', group: 'deployment', page: 'dashboard-deployment-board', has_menu: true, is_cached: false }
        },
        {
            path: 'deployment/promoter-pool',
            component: () =>
                import ('@/views/epanel/dashboard/deployment/promoter-pool/PromoterPool'),
            name: 'promoter-pool',
            meta: { title: 'Current Available Promoter', group: 'deployment', page: 'promoter-pool', has_menu: true, is_cached: false }
        },
        {
            path: 'outlet-visiting/me',
            component: () =>
                import ('@/views/epanel/dashboard/outletVisiting/me/DashboardOutletVisiting'),
            name: 'dashboard-my-outlet-visiting',
            meta: { title: 'My Outlet Visiting', group: 'outlet-visiting', page: 'dashboard-my-outlet-visiting', has_menu: true, is_cached: false }
        },
        {
            path: 'outlet-visiting/me/:id/feedback',
            component: () =>
                import ('@/views/epanel/dashboard/outletVisiting/me/DashboardOutletVisitingFeedbackForm'),
            name: 'dashboard-my-outlet-visiting-feedback',
            meta: { title: 'My Outlet Visiting', group: 'outlet-visiting', page: 'dashboard-my-outlet-visiting-feedback', has_menu: false, is_cached: false }
        },
        {
            path: 'outlet-visiting/schedule',
            component: () =>
                import ('@/views/epanel/dashboard/outletVisiting/DashboardOutletVisiting'),
            name: 'dashboard-outlet-visiting',
            meta: { title: 'Outlet Visiting', group: 'outlet-visiting', page: 'dashboard-outlet-visiting', has_menu: true, is_cached: false }
        },
        {
            path: 'payroll',
            component: EmptyLayout,
            children: [{
                    path: 'project',
                    component: () =>
                        import ('@/views/epanel/payroll/project/PayrollProjectList'),
                    name: 'payroll-project',
                    meta: { title: "Project Payroll Management", group: 'payroll', page: 'payroll-project', has_menu: true, is_cached: false }
                },
                {
                    path: 'project/:id/info',
                    component: () =>
                        import ('@/views/epanel/payroll/project/PayrollProjectInfo'),
                    name: 'payroll-project-info',
                    meta: { title: "Project Payroll Info", group: 'payroll', page: 'payroll-project-info', has_menu: false, is_cached: false }
                },
                {
                    path: 'promoter',
                    component: () =>
                        import ('@/views/epanel/payroll/promoter/PayrollPromoterList'),
                    name: 'payroll-promoter',
                    meta: { title: "Promoter Payroll Management", group: 'payroll', page: 'payroll-promoter', has_menu: true, is_cached: false }
                },
                {
                    path: 'promoter/:id/info',
                    component: () =>
                        import ('@/views/epanel/payroll/promoter/PayrollPromoterInfo'),
                    name: 'payroll-promoter-info',
                    meta: { title: "Promoter Payroll Info", group: 'payroll', page: 'payroll-promoter-info', has_menu: false, is_cached: false }
                },
                {
                    path: 'payslip',
                    component: () =>
                        import ('@/views/epanel/payroll/payslip/PayrollPayslipList'),
                    name: 'payslip',
                    meta: { title: "Payslip Management", group: 'payroll', page: 'payslip', has_menu: true, is_cached: false }
                },
                {
                    path: 'payslip/:id/info',
                    component: () =>
                        import ('@/views/epanel/payroll/payslip/PayrollPayslipInfo'),
                    name: 'payslip-info',
                    meta: { title: "Payslip Info", group: 'payroll', page: 'payslip-info', has_menu: false, is_cached: false }
                },
                {
                    path: 'cpf',
                    component: () =>
                        import ('@/views/epanel/payroll/cpf/CpfPayrollList'),
                    name: 'cpf-payroll',
                    meta: { title: "CPF Payroll", group: 'payroll', page: 'cpf-payroll', has_menu: true, is_cached: false }
                },
                {
                    path: 'non-cpf',
                    component: () => 
                        import ('@/views/epanel/payroll/nonCPF/NonCpfPayrollList'),
                    name: 'non-cpf-payroll',
                    meta: { title: "Non CPF Payroll", group: 'payroll', page: 'non-cpf-payroll', has_menu: true, is_cached: false }
                },
            ]
        },
        {
            path: 'report',
            component: EmptyLayout,
            children: [{
                    path: 'manpower',
                    component: () =>
                        import ('@/views/epanel/report/manpower/ManpowerPanel'),
                    name: 'report-manpower',
                    meta: { title: "Manpower Report", group: 'report', page: 'report-manpower', has_menu: true, is_cached: false }
                },
                {
                    path: 'manpower-client',
                    component: () =>
                        import ('@/views/epanel/report/manpower-client/ManpowerPanel'),
                    name: 'report-manpower-client',
                    meta: { title: "Manpower (Client) Report", group: 'report', page: 'report-manpower-client', has_menu: true, is_cached: false }
                },
                {
                    path: 'sales-brand',
                    component: () =>
                        import ('@/views/epanel/report/sales/brand-promoter/SalesTable'),
                    name: 'sales-brand-promoter',
                    meta: { title: "Brand Promoter Sales Report", group: 'report', page: 'sales-brand-promoter', has_menu: true, is_cached: false }
                },
                {
                    path: 'sales-weekly',
                    component: () =>
                        import ('@/views/epanel/report/sales/weekly/SalesTable'),
                    name: 'sales-weekly',
                    meta: { title: "Weekly Sales Report", group: 'report', page: 'sales-weekly', has_menu: true, is_cached: false }
                },
                {
                    path: 'campaign-outlet',
                    component: () =>
                        import ('@/views/epanel/report/campaign/outlet/CampaignReportList'),
                    name: 'campaign-outlet',
                    meta: { title: "Outlet Campaign Report", group: 'report', page: 'campaign-outlet', has_menu: true, is_cached: false }
                },
                {
                    path: 'campaign-monthly',
                    component: () =>
                        import ('@/views/epanel/report/campaign/monthly/CampaignReportList'),
                    name: 'campaign-monthly',
                    meta: { title: "Monthly Campaign Report", group: 'report', page: 'campaign-monthly', has_menu: true, is_cached: false }
                },
                {
                    path: 'timesheet',
                    component: () =>
                        import ('@/views/epanel/report/timesheet/TimesheetList'),
                    name: 'timesheet',
                    meta: { title: "Timesheet Management", group: 'report', page: 'timesheet', has_menu: true, is_cached: true }
                },
                {
                    path: 'timesheet/:id/info',
                    component: () =>
                        import ('@/views/epanel/report/timesheet/TimesheetInfo'),
                    name: 'timesheet-info',
                    meta: { title: "Timesheet Info", group: 'report', page: 'timesheet', has_menu: false, is_cached: false }
                },
                {
                    path: 'attendance-photo',
                    component: () =>
                        import ('@/views/epanel/report/activity/attendance/photo/AttendancePhotoReportList'),
                    name: 'attendance',
                    meta: { title: "Attendance Photo's Project Management", group: 'report', page: 'attendance-photo', has_menu: true, is_cached: true }
                },
                {
                    path: 'attendance-photo/:id/info',
                    component: () =>
                        import ('@/views/epanel/report/activity/attendance/photo/AttendancePhotoReportInfo'),
                    name: 'attendance-info',
                    meta: { title: "Project Attendance Photo Info", group: 'report', page: 'attendance-photo', has_menu: false, is_cached: false }
                },
                {
                    path: 'visiting-feedback',
                    component: () =>
                        import ('@/views/epanel/report/activity/visiting/OutletVisitingFeedbackReportList'),
                    name: 'visiting-feedback',
                    meta: { title: "Outlet Visiting Feedback's Project Management", group: 'report', page: 'visiting-feedback', has_menu: true, is_cached: true }
                },
                {
                    path: 'visiting-feedback/:id/info',
                    component: () =>
                        import ('@/views/epanel/report/activity/visiting/OutletVisitingFeedbackReportInfo'),
                    name: 'visiting-feedback-info',
                    meta: { title: "Outlet Visiting Feedback Info", group: 'report', page: 'visiting-feedback-info', has_menu: false, is_cached: false }
                },
                {
                    path: 'promoter-listing',
                    component: () =>
                        import ('@/views/epanel/report/promoter/PromoterListing'),
                    name: 'promoter-listing',
                    meta: { title: "Promoter Listing Report", group: 'report', page: 'promoter-listing', has_menu: true, is_cached: true }
                },
                {
                    path: 'promoter-attendance',
                    component: () =>
                        import ('@/views/epanel/report/promoter/attendance/PromoterAttendanceListing'),
                    name: 'promoter-attendance',
                    meta: { title: "Promoter Attendance Report", group: 'report', page: 'promoter-attendance', has_menu: true, is_cached: true }
                },
            ]
        },
        {
            path: 'setting',
            component: EmptyLayout,
            children: [{
                    path: 'bank',
                    component: () =>
                        import ('@/views/epanel/setting/bank/BankList'),
                    name: 'setting-bank-list',
                    meta: { title: "Bank Management", group: 'setting', page: 'setting-bank-list', has_menu: true, is_cached: false }
                },
                {
                    path: 'bank/:id/branch',
                    component: () =>
                        import ('@/views/epanel/setting/bank/branch/BankBranchList'),
                    name: 'setting-bank-branch-list',
                    meta: { title: "Bank Branch Management", group: 'setting', page: 'setting-bank-branch-list', has_menu: true, is_cached: false }
                },
                {
                    path: 'agency',
                    component: () =>
                        import ('@/views/epanel/setting/agency/AgencyList'),
                    name: 'setting-agency-list',
                    meta: { title: "Agency Management", group: 'setting', page: 'setting-agency-list', has_menu: true, is_cached: false }
                },
                {
                    path: 'agency/:id/info',
                    component: () =>
                        import ('@/views/epanel/setting/agency/AgencyInfo'),
                    name: 'setting-agency',
                    meta: { title: "Agency Info", group: 'setting', page: 'setting-agency', has_menu: true, is_cached: false }
                },
                {
                    path: 'district',
                    component: () =>
                        import ('@/views/epanel/setting/district/DistrictList'),
                    name: 'setting-district-list',
                    meta: { title: "District Management", group: 'setting', page: 'setting-district-list', has_menu: true, is_cached: false }
                },
                {
                    path: 'cpf',
                    component: () =>
                        import ('@/views/epanel/setting/cpf/CPFList'),
                    name: 'setting-cpfs',
                    meta: { title: "CPF Management", group: 'setting', page: 'setting-cpfs', has_menu: true, is_cached: false }
                }
            ]
        },
    ]
}

export default router