import { get, put } from '@/utils/request'


export function getPermissions(data) {
    return get('api/epanel/account/permissions/', data)
}

export function updatePermission(id, data) {
    return put('api/epanel/account/permissions/' + id + '/', data)
}

