import { getAddresses, createAddress, updateAddress, deleteAddress } from '@/api/epanel/address'

const state = {
	items: [],
	headers: [],
	pagination: {},
	address: {}
}

const mutations = {
	SET_STATE: (state, data) => {
		state.items = data.items
		state.headers = data.headers
		state.pagination = data.pagination
	},
    SET_ADDRESS: (state, data) => {
        state.address = data
    }
}

const actions = {
	getAddresses({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getAddresses(paramsData).then(response => {
				commit('SET_ADDRESS', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	createAddress({ commit }, data) {
		return new Promise((resolve, reject) => {
            createAddress(data).then(response => {
                commit('SET_ADDRESS', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
    updateAddress({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateAddress(data.id, data.object).then(response => {
                commit('SET_ADDRESS', response.data.object)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    deleteAddress({ commit }, data) {
        return new Promise((resolve, reject) => {
            deleteAddress(data.id).then(response => {
                commit('SET_ADDRESS', {})
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    }
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}