<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<script>

export default {
	name: 'App',
	created() {
		let state = sessionStorage.getItem('blink-data')
		if(state) {
			this.$store.replaceState(Object.assign(this.$store.state, JSON.parse(state)))
			sessionStorage.clear()
		}
	},
	mounted() {
		window.addEventListener('beforeunload', () => {
			sessionStorage.setItem('blink-data', JSON.stringify(this.$store.state))
		})
	},
	beforeDestory() {
		window.removeEventListener('beforeunload')
	}
};
</script>
