import { Base64 } from 'js-base64'

export function encodeBase64(code) {
	if(!code) {
		return ''
	}
	return Base64.encode(code)
}

export function decodeBase64(code) {
    if(!code) {
        return ''
    }
    return Base64.decode(code)
}


export function stringifyJSON(text) {
    return JSON.stringify(text)
}

export function parseJSON(text) {
    return JSON.parse(text)
}