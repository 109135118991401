import { getPayrollProjects, createPayrollProjects, getPayrollProjectPayments, publishPayrollProject, updatePayrollProjectPayments,
         getPayrollPromoters, getPayrollPromoterPayments, createPayrollPromoterPayments, updatePayrollPromoterPayments,
         getPayrollPayslips, getPayrollPayslipAccounts, createPayrollPayslipAccounts } from '@/api/epanel/payroll'

const state = {
	items: [],
	headers: [],
	pagination: {},
}

const mutations = {
	SET_STATE: (state, data) => {
		state.items = data.items
		state.headers = data.headers
		state.pagination = data.pagination
	},
}

const actions = {
	getPayrollProjects({ commit }, data) {
		return new Promise((resolve, reject) => {
			getPayrollProjects(data).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	createPayrollProjects({ commit }, data) {
		return new Promise((resolve, reject) => {
			createPayrollProjects(data).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getPayrollProjectPayments({ commit }, data) {
		return new Promise((resolve, reject) => {
			getPayrollProjectPayments(data.id).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	publishPayrollProject({ commit }, data) {
		return new Promise((resolve, reject) => {
			publishPayrollProject(data.id, data.object).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updatePayrollProjectPayments({ commit }, data) {
		return new Promise((resolve, reject) => {
			updatePayrollProjectPayments(data.id, data.object).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
    getPayrollPromoters({ commit }, data) {
        return new Promise((resolve, reject) => {
            getPayrollPromoters(data).then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getPayrollPromoterPayments({ commit }, data) {
        return new Promise((resolve, reject) => {
            getPayrollPromoterPayments(data.id, data.object).then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    createPayrollPromoterPayments({ commit }, data) {
        return new Promise((resolve, reject) => {
            createPayrollPromoterPayments(data.id).then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    updatePayrollPromoterPayments({ commit }, data) {
        return new Promise((resolve, reject) => {
            updatePayrollPromoterPayments(data.id).then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
	getPayrollPayslips({ commit }, data) {
        return new Promise((resolve, reject) => {
            getPayrollPayslips(data).then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getPayrollPayslipAccounts({ commit }, data) {
        return new Promise((resolve, reject) => {
            getPayrollPayslipAccounts(data.id).then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    createPayrollPayslipAccounts({ commit }, data) {
		return new Promise((resolve, reject) => {
			createPayrollPayslipAccounts(data.id, data.object).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}