import Cookies from 'js-cookie'
import { parseJSON, stringifyJSON } from '@/utils/encodes'

const accessToken = 'access-token'

export function getToken() {
	return Cookies.get(accessToken)
}

export function setToken(token) {
	return Cookies.set(accessToken, token)
}

export function removeToken(){
	return Cookies.remove(accessToken)
}

export function getSessionStorage(name, key) {
    var state = sessionStorage.getItem(name)
    state = state?parseJSON(state):null

    if(!state){
        return null
    }

    return key?state[key]:null
}

export function addSessionStorage(name, key, value) {
    var state = sessionStorage.getItem(name)
    if(!state) {
        state = {}
        state[key] = value
    }else{
        state = parseJSON(state)
        state[key] = value
    }
    sessionStorage.setItem(name, stringifyJSON(state))
}

export function removeSessionStorage(name){
	let state = sessionStorage.getItem(name)
	if(state) {
		sessionStorage.removeItem(name)
	}
}

export function getLocalStorage(name, key) {
    var state = localStorage.getItem(name)
    state = state?parseJSON(state):null

    if(!state){
        return null
    }

    return key?state[key]:null
}

export function addLocalStorage(name, key, value) {
    var state = localStorage.getItem(name)
    if(!state) {
        state = {}
        state[key] = value
    }else{
        state = parseJSON(state)
        state[key] = value
    }
    localStorage.setItem(name, stringifyJSON(state))
}

export function removeLocalStorage(name){
	let state = localStorage.getItem(name)
	if(state) {
		localStorage.removeItem(name)
	}
}