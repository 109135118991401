<template>
	<div>
		<v-app>
			<v-main>
				<v-app-bar app dense color="primary white--text elevation-0">
					<v-app-bar-nav-icon class="d-flex d-sm-none d-md-none" color="white" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
					<div v-text="this.$route.meta.title"></div>
				</v-app-bar>
				<v-navigation-drawer app width="240" v-model="drawer" v-if="$route.meta.has_menu">
					<v-toolbar color="primary" class="white--text elevation-0" height="48">Blink System</v-toolbar>
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title class="text-h6" v-text="object.full_name"></v-list-item-title>
								<v-list-item-subtitle v-text="object.role"></v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-action>
                                <v-btn small icon plain to="/epanel/account/profile">
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-btn>
							</v-list-item-action>
						</v-list-item>
					</v-list>
					<v-divider></v-divider>
					<v-list dense shaped>
						<v-list-item link to="/epanel/dashboard" :active-class="isPage('dashboard-activation')">
							<v-list-item-icon>
								<v-icon>mdi-home</v-icon>
							</v-list-item-icon>
							<v-list-item-title>Dashboard</v-list-item-title>
						</v-list-item>
						<template v-for="(menu, index) in menus">
                            <v-list-group no-action :prepend-icon="menu.icon" :key="index" :value="isGroup(menu.code)" v-if="menu.is_group">
                                <template v-slot:activator>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="menu.name"></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                                <v-list-item link :to="submenu.link" :key="sindex" v-for="(submenu, sindex) in menu.submenus">
									<!--<p>{{submenu}}</p>-->
                                    <v-list-item-title v-text="submenu.name"></v-list-item-title>
                                </v-list-item>
                            </v-list-group>
                            <v-list-item link :key="index" :to="menu.link" :active-class="isPage(menu.code)" v-if="!menu.is_group">
                                <v-list-item-icon>
                                    <v-icon v-text="menu.icon"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-title v-text="menu.name"></v-list-item-title>
                            </v-list-item>
						</template>
						<v-list-item link @click="logout">
							<v-list-item-icon>
								<v-icon>mdi-exit-to-app</v-icon>
							</v-list-item-icon>
							<v-list-item-title>Logout</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-navigation-drawer>
				<v-container fluid class="pa-4">
					<transition name="fade-transform" mode="out-in">
						<keep-alive v-if="$route.meta.is_cached">
							<router-view></router-view>
						</keep-alive>
						<router-view v-if="!$route.meta.is_cached"></router-view>
					</transition>
				</v-container>
				<v-footer app></v-footer>
			</v-main>
		</v-app>
	</div>
</template>


<script>

import { getLocalStorage, removeLocalStorage } from '@/utils/auth'
import { redirectNameTo } from '@/utils/route'

export default {
	name: 'MainLayout',
	data() {
		return {
			drawer: true,
			menus: [],
            object: {
                full_name: '',
                role: ''
            }
		}
	},
    mounted: function() {
        this.getData()
    },
	methods: {
		logout: function() {
            this.$store.dispatch('auth/logout').then(() => {
                removeLocalStorage('blink-data')
                redirectNameTo(this.$router, 'epanel-login')
			}).catch(() => {
				this.loading = false
			})
		},
        isGroup: function(group) {
            return this.$route.meta.group === group
        },
        isPage: function(page) {
            return this.$route.meta.page === page?'primary--text':''
        },
        getData: function(){
            this.menus = getLocalStorage('blink-data', 'menus')
            if(this.$store.getters.auth.full_name){
                this.object = this.$store.getters.auth
            }else{
                let user = getLocalStorage('blink-data', 'user')
                if(user){
                    this.object = user
                }
            }
        }
	}
}

</script>