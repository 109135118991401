import { getPermissions, updatePermission } from '@/api/epanel/permission'

const state = {
	items: [],
	headers: [],
	pagination: {}
}

const mutations = {
	SET_STATE: (state, data) => {
		state.items = data.items
		state.headers = data.headers
		state.pagination = data.pagination
	},

}

const actions = {
	getPermissions({ commit }, data) {
		return new Promise((resolve, reject) => {
			getPermissions(data).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updatePermission({ commit }, data) {
		return new Promise((resolve, reject) => {
			updatePermission(data.id, data.object).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}