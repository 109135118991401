import { getCompanies, getCompany, createCompany, updateCompany, deleteCompany,
         getCompanyBanks, createCompanyBank, updateCompanyBank, deleteCompanyBank } from '@/api/epanel/company'

const state = {
	items: [],
	headers: [],
	pagination: {},
	company: {}
}

const mutations = {
	SET_STATE: (state, data) => {
		state.items = data.items
		state.headers = data.headers
		state.pagination = data.pagination
	},
    SET_COMPANY: (state, data) => {
		state.company = data
	},
}

const actions = {
	getCompanies({ commit }, data) {
		return new Promise((resolve, reject) => {
			getCompanies(data).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getCompany({ commit }, data) {
		return new Promise((resolve, reject) => {
			getCompany(data.id).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	createCompany({ commit }, data) {
		return new Promise((resolve, reject) => {
            createCompany(data).then(response => {
                commit('SET_COMPANY', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateCompany({ commit }, data) {
		return new Promise((resolve, reject) => {
            updateCompany(data.id, data.object).then(response => {
                commit('SET_COMPANY', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	deleteCompany({ commit }, data) {
        return new Promise((resolve, reject) => {
            deleteCompany(data.id).then(response => {
                commit('SET_COMPANY', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
	getCompanyBanks({ commit }, data) {
		return new Promise((resolve, reject) => {
			getCompanyBanks(data).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
    createCompanyBank({ commit }, data) {
        return new Promise((resolve, reject) => {
            createCompanyBank(data).then(response => {
                commit('SET_COMPANY', response.data.object)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    updateCompanyBank({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateCompanyBank(data.id, data.object).then(response => {
                commit('SET_COMPANY', response.data.object)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    deleteCompanyBank({ commit }, data) {
        return new Promise((resolve, reject) => {
            deleteCompanyBank(data.id, data.object).then(response => {
                commit('SET_COMPANY', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}