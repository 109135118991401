import { getAgencies, getAgency, getAgencyFunds, createAgencyFund, updateAgencyFund, deleteAgencyFund,
         getDistricts, createDistrict, updateDistrict, getCPFs, updateCPF } from '@/api/epanel/setting'

const state = {
	items: [],
	headers: [],
	pagination: {},
}

const mutations = {
	SET_STATE: (state, data) => {
		state.items = data.items
		state.headers = data.headers
		state.pagination = data.pagination
	},
}

const actions = {
	getAgencies({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getAgencies(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getAgency({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getAgency(paramsData.id).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getAgencyFunds({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getAgencyFunds(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	createAgencyFund({ commit }, data) {
		return new Promise((resolve, reject) => {
			createAgencyFund(data).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateAgencyFund({ commit }, data) {
		return new Promise((resolve, reject) => {
			updateAgencyFund(data.id, data.object).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	deleteAgencyFund({ commit }, data) {
		return new Promise((resolve, reject) => {
			deleteAgencyFund(data.id).then(response => {
				commit('SET_STATE', {})
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getDistricts({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getDistricts(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	createDistrict({ commit }, data) {
		return new Promise((resolve, reject) => {
			createDistrict(data).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateDistrict({ commit }, data) {
		return new Promise((resolve, reject) => {
			updateDistrict(data.id, data.object).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getCPFs({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getCPFs(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateCPF({ commit }, data) {
		return new Promise((resolve, reject) => {
			updateCPF(data.id, data.object).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}