import { getRoles, getRolePermissions, updateRolePermissions } from '@/api/epanel/role'

const state = {
	items: [],
	headers: [],
	pagination: {}
}

const mutations = {
	SET_STATE: (state, data) => {
		state.items = data.items
		state.headers = data.headers
		state.pagination = data.pagination
	},

}

const actions = {
	getRoles({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getRoles(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getRolePermissions({ commit }, data) {
		return new Promise((resolve, reject) => {
			getRolePermissions(data.id, data.object).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateRolePermissions({ commit }, data) {
		return new Promise((resolve, reject) => {
			updateRolePermissions(data.id, data.object).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}