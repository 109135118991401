import { get, post, put } from '@/utils/request'

export function getClients(params) {
	return get('api/epanel/clients/', params)
}

export function createClient(data) {
    return post('api/epanel/clients/', data)
}

export function updateClient(id, data) {
    return put('api/epanel/clients/' + id + '/', data)
}
