import { get, post, put, remove } from '@/utils/request'

export function getProjects(params) {
	return get('api/epanel/projects/', params)
}

export function createProject(data) {
    return post('api/epanel/projects/', data)
}

export function retrieveProject(id, data) {
    return get('api/epanel/projects/' + id + '/', data)
}

export function updateProject(id, data) {
    return put('api/epanel/projects/' + id + '/', data)
}

export function deleteProject(id, data) {
    return remove('api/epanel/projects/' + id + '/', data)
}

export function archiveProject(id, data) {
    return put('api/epanel/projects/' + id + '/archive/', data)
}

export function getProjectResources(params) {
    return get('api/epanel/projects/resources/', params)
}

export function createProjectResource(data) {
    return post('api/epanel/projects/resources/', data)
}

export function updateProjectResource(id, data) {
    return put('api/epanel/projects/resources/' + id + '/', data)
}

export function deleteProjectResource(id, data) {
    return remove('api/epanel/projects/resources/' + id + '/', data)
}

export function getProjectSKUs(params) {
    return get('api/epanel/projects/skus/', params)
}

export function createProjectSKU(data) {
    return post('api/epanel/projects/skus/', data)
}

export function updateProjectSKU(id, data) {
    return put('api/epanel/projects/skus/' + id + '/', data)
}

export function deleteProjectSKU(id, data) {
    return remove('api/epanel/projects/skus/' + id + '/', data)
}

export function getProjectPromoters(params) {
    return get('api/epanel/projects/promoters/', params)
}

export function getProjectCampaigns(params) {
    return get('api/epanel/projects/campaigns/', params)
}

export function createProjectCampaign(data) {
    return post('api/epanel/projects/campaigns/', data)
}

export function getProjectCampaign(id) {
    return get('api/epanel/projects/campaigns/' + id + '/')
}

export function updateProjectCampaign(id, data) {
    return put('api/epanel/projects/campaigns/' + id + '/', data)
}

export function deleteProjectCampaign(id, data) {
    return remove('api/epanel/projects/campaigns/' + id + '/', data)
}

export function getCampaignTimesheet(id, data) {
    return get('api/epanel/projects/campaigns/' + id + '/timesheet/', data)
}

export function getCampaignTimesheetShift(ids) {
    return get('api/epanel/projects/campaigns/' + ids.campaign + '/timesheet/' + ids.shift + '/')
}



