import { get, post, remove } from '@/utils/request'

export function getShifts(data) {
	return get('api/mobile/shifts/', data)
}

export function getShiftActivity(data) {
	return get('api/mobile/shifts/activity/', data)
}

export function getShiftAttendance(data) {
	return get('api/mobile/shifts/attendance/', data)
}

export function createShiftAttendance(data) {
    return post('api/mobile/shifts/attendance/', data)
}

export function getShiftSales(data) {
	return get('api/mobile/shifts/sales/', data)
}

export function getShiftFeedbacks(data) {
    return get('api/mobile/shifts/feedbacks/', data)
}

export function updateShiftStatus(data) {
    return post('api/mobile/shifts/activity/', data)
}

export function updateShiftSales(data) {
    return post('api/mobile/shifts/sales/', data)
}

export function updateShiftSurvey(data) {
    return post('api/mobile/shifts/sales/survey/', data)
}

export function createShiftFeedback(data) {
    return post('api/mobile/shifts/feedbacks/', data)
}

export function deleteShiftFeedback(data) {
    return remove('api/mobile/shifts/feedbacks/', data)
}

export function getShiftResources(data) {
    return get('api/mobile/shifts/resources/', data)
}




