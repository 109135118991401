import { get, post, put } from '@/utils/request'

export function getPromoters(params) {
	return get('api/epanel/account/promoters/', params)
}

export function getPromoter() {
    return get('api/epanel/account/promoters/add/')
}

export function retrievePromoter(id, data) {
    return get('api/epanel/account/promoters/' + id + '/', data)
}

export function createPromoter(data) {
    return post('api/epanel/account/promoters/add/', data)
}

export function updatePromoterStatus(id, data) {
    return put('/api/epanel/account/promoters/' + id + '/status/', data)
}

export function updatePromoterProfile(id, data) {
    return put('/api/epanel/account/promoters/' + id + '/profile/', data)
}

export function updatePromoterAddress(id, data) {
    return put('/api/epanel/account/promoters/' + id + '/address/', data)
}

export function updatePromoterBank(id, data) {
    return put('/api/epanel/account/promoters/' + id + '/bank/', data)
}

export function getPromoterLogs(id, data) {
    return get('api/epanel/account/promoters/' + id + '/logs/', data)
}