import { getLocalStorage } from '@/utils/auth'
import axios from 'axios'
import store from '@/store'

const service = axios.create({
	baseURL: '/',
	timeout: 60000,
	withCredentials: false,
	headers: {
		'Access-Control-Allow-Origin': '*',
	}
})

service.interceptors.request.use(
	config => {
        if(store.getters.auth.access_token) {
            config.headers['Authorization'] = 'Token ' + store.getters.auth.access_token
        }else{
            let user = getLocalStorage('blink-data', 'user')
            var accessToken = user?user["access_token"]:null
            if(accessToken){
                config.headers['Authorization'] = 'Token ' + accessToken
            }
        }

		return config
	},
	error => {
		return Promise.reject(error)
	}
)

service.interceptors.response.use(
	response => {
		const data = response.data

		if(data.code === -1){
            return data
		}

		if([10000, 10001].includes(data.code)){
            if(window.location.pathname.indexOf('epanel') > -1){
                window.location.href = '/epanel/login?e=' + data.code + '&redirect=' + window.location.pathname
            }else{
                window.location.href = '/login?e=' + data.code + '&redirect=' + window.location.pathname
            }
        }else if([10002].includes(data.code)){
            if(window.location.pathname.indexOf('epanel') === -1){
                window.location.href = '/login?e=' + data.code + '&redirect=' + window.location.pathname
            }
        }

        return Promise.reject(data)
	},
	error => {
        console.log('error = ', error)
        return Promise.reject(error)
	}
)

export default service


export function get(url, params) {
	return service({
		url: url,
		method: 'get',
		params
	})
}

export function post(url, data) {
	return service({
		url: url,
		method: 'post',
		data
	})
}

export function put(url, data) {
	return service({
		url: url,
		method: 'put',
		data
	})
}

export function remove(url, data) {
    return service.delete(url, {
        data: data
    })
}
