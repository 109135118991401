import { getAdmins, createAdmin, updateAdmin, getAdminPermissions, updateAdminPermissions } from '@/api/epanel/admin'

const state = {
	items: [],
	headers: [],
	pagination: {}
}

const mutations = {
	SET_STATE: (state, data) => {
		state.items = data.items
		state.headers = data.headers
		state.pagination = data.pagination
	},

}

const actions = {
	getAdmins({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getAdmins(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	createAdmin({ commit }, data) {
		return new Promise((resolve, reject) => {
            createAdmin(data).then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateAdmin({ commit }, data) {
		return new Promise((resolve, reject) => {
            updateAdmin(data.id, data.object, "profile").then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateAdminPassword({ commit }, data) {
		return new Promise((resolve, reject) => {
            updateAdmin(data.id, data.object, "password").then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
    updateAdminStatus({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateAdmin(data.id, data.object, "status").then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getAdminPermissions({ commit }, data) {
        return new Promise((resolve, reject) => {
            getAdminPermissions(data.id, data.object).then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    updateAdminPermissions({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateAdminPermissions(data.id, data.object).then(response => {
                commit('SET_STATE', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    }
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}