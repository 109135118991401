import { get, post, put, remove } from '@/utils/request'

export function getAgencies() {
	return get('api/epanel/setting/agencies/')
}

export function getAgency(id) {
    return get('api/epanel/setting/agencies/' + id + '/')
}

export function getAgencyFunds(data) {
	return get('api/epanel/setting/agencies/funds/', data)
}

export function createAgencyFund(data) {
	return post('api/epanel/setting/agencies/funds/', data)
}

export function updateAgencyFund(id, data) {
    return put('api/epanel/setting/agencies/funds/' + id + '/', data)
}

export function deleteAgencyFund(id) {
    return remove('api/epanel/setting/agencies/funds/' + id + '/')
}


export function getDistricts(data) {
	return get('api/epanel/setting/districts/', data)
}

export function createDistrict(data) {
	return post('api/epanel/setting/districts/', data)
}

export function updateDistrict(id, data) {
    return put('api/epanel/setting/districts/' + id + '/', data)
}

export function getCPFs(data) {
    return get('api/epanel/setting/cpfs/', data)
}

export function updateCPF(id, data) {
    return put('api/epanel/setting/cpfs/' + id + '/', data)
}

