import { get, post, put } from '@/utils/request'

export function getDashboard(params) {
	return get('api/epanel/dashboard/home/', params)
}

export function getApplications(params) {
    return get('api/epanel/dashboard/applications/', params)
}

export function getApplication(id, params) {
    return get('api/epanel/dashboard/applications/' + id + '/', params)
}

export function updateApplication(id, data) {
    return put('api/epanel/dashboard/applications/' + id + '/', data)
}

export function getActivationDashboard(params) {
    return get('api/epanel/dashboard/activations/', params)
}

export function getKivPromoterDashboard(params) {
    return get('api/epanel/dashboard/kiv/', params)
}

export function updateActivationShift(data) {
    return post('api/epanel/dashboard/activations/shift/', data)
}

export function updateKivPromoterDashboard(data) {
    return post('api/epanel/dashboard/kiv/promoter_status/', data)
}

export function getDeploymentDashboard(params) {
    return get('api/epanel/dashboard/deployment/', params)
}

export function getOutletVisitingDashboard(params) {
    return get('api/epanel/dashboard/visitings/', params)
}

export function updateOutletVisitingShift(data) {
    return post('api/epanel/dashboard/visitings/', data)
}

export function getMyOutletVisitingDashboard(params) {
    return get('api/epanel/dashboard/visitings/me/', params)
}

export function getMyOutletVisitingInfo(id) {
    return get('api/epanel/dashboard/visitings/' + id + '/')
}

export function createOutletVisitingFeedback(id, data) {
    return post('api/epanel/dashboard/visitings/' + id + '/', data)
}

export function getDashboardPromoterList(params) {
    return get('api/epanel/dashboard/promoter/', params)
}
