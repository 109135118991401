import { get, post, put, remove } from '@/utils/request'

export function getAddressOutlets(id, params) {
	return get('api/epanel/store/addresses/' + id + '/outlets/', params)
}

export function getChainOutlets(id, params) {
	return get('api/epanel/store/chains/' + id + '/outlets/', params)
}

export function createAddressOutlet(id, data) {
    return post('api/epanel/store/addresses/' + id + '/outlets/', data)
}

export function createChainOutlet(id, data) {
    return post('api/epanel/store/chains/' + id + '/outlets/', data)
}

export function updateAddressOutlet(ids, data) {
    return put('api/epanel/store/addresses/' + ids.address + '/outlets/' + ids.outlet + '/', data)
}

export function updateChainOutlet(ids, data) {
    return put('api/epanel/store/chains/' + ids.chain + '/outlets/' + ids.outlet + '/', data)
}

export function deleteChainOutlet(id) {
    return put('api/epanel/store/outlets/' + id + '/')
}

export function deleteAddressOutlet(id) {
    return remove('api/epanel/store/outlets/' + id + '/')
}
