import { get, post, put, remove } from '@/utils/request'

export function getAddresses(params) {
	return get('api/epanel/store/addresses/', params)
}

export function createAddress(data) {
    return post('api/epanel/store/addresses/', data)
}

export function updateAddress(id, data) {
    return put('api/epanel/store/addresses/' + id + '/', data)
}

export function deleteAddress(id) {
    return remove('api/epanel/store/addresses/' + id + '/')
}
