import { get, post, put } from '@/utils/request'

export function getPayrollProjects(data) {
	return get('api/epanel/payroll/projects/', data)
}

export function createPayrollProjects(data) {
    return post('api/epanel/payroll/projects/', data)
}

export function getPayrollProjectPayments(id) {
	return get('api/epanel/payroll/projects/' + id + '/')
}

export function publishPayrollProject(id, data) {
    return put('api/epanel/payroll/projects/' + id + '/publish/', data)
}

export function updatePayrollProjectPayments(id, data) {
    return put('api/epanel/payroll/projects/' + id + '/', data)
}

export function getPayrollPromoters(data) {
	return get('api/epanel/payroll/promoters/', data)
}

export function getPayrollPromoterPayments(id, data) {
	return get('api/epanel/payroll/promoters/' + id + '/', data)
}

export function createPayrollPromoterPayments(id) {
    return post('api/epanel/payroll/promoters/' + id + '/')
}

export function updatePayrollPromoterPayments(id) {
    return put('api/epanel/payroll/promoters/' + id + '/')
}

export function getPayrollPayslips(data) {
	return get('api/epanel/payroll/payslips/', data)
}

export function getPayrollPayslipAccounts(id) {
	return get('api/epanel/payroll/payslips/' + id + '/')
}

export function createPayrollPayslipAccounts(id, data) {
    return post('api/epanel/payroll/payslips/' + id + '/', data)
}

