import Vue from 'vue'
import Router from 'vue-router'
import { getToken } from '@/utils/auth'

Vue.use(Router)

import epanelRouter from './epanel'
import mobileRouter from './mobile'

export const constantRoutes = [{
        path: '',
        component: () =>
            import ('@/views/mobile/login/LoginForm'),
    },
    {
        path: '/page-not-found',
        component: () =>
            import ('@/views/shared/PageNotFound'),
        name: 'page-not-found'
    },
    {
        path: '/epanel/login',
        component: () =>
            import ('@/views/epanel/login/LoginForm'),
        name: 'epanel-login'
    },
    {
        path: '/epanel/logout',
        component: () =>
            import ('@/views/shared/LogoutPage'),
        name: 'epanel-logout'
    },
    {
        path: '/login',
        component: () =>
            import ('@/views/mobile/login/LoginForm'),
        name: 'mobile-login'
    },
    {
        path: '/logout',
        component: () =>
            import ('@/views/shared/LogoutPage'),
        name: 'logout'
    },
    {
        path: '/register',
        component: () =>
            import ('@/views/mobile/register/RegistrationForm'),
        name: 'registration'
    },
    epanelRouter,
    mobileRouter,
]

const createRouter = () => new Router({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()

const loginPages = ['/', '/login', '/login/'];
const adminLoginPage = ['/epanel/login'];
const nonRedirectPages = ['/epanel/login', '/epanel/logout', '/login', '/logout', '/register']
const emptyPages = ['/epanel']

router.beforeEach(async(to, from, next) => {
    const isAccessToken = getToken() ? true : false
    const toPath = to.path
    const isNonRedirectPage = nonRedirectPages.includes(toPath)
    const isAdminPage = toPath.indexOf('/epanel/') > -1

    if (emptyPages.includes(toPath) || to.matched.length === 0) {
        next('/page-not-found')
    }

    if (isAccessToken) {
        if (adminLoginPage.includes(toPath)) {
            next('/epanel/dashboard')
        } else if (loginPages.includes(toPath)) {
            next('/today')
        }
    }

    if (!isAccessToken && !isNonRedirectPage) {
        next((isAdminPage ? '/epanel/login' : '/login') + '?redirect=' + toPath)
    }

    //    else if(isAccessToken && !isNonRedirectPage){
    //        if(isAdminPage && isPromoter) {
    //            next('/dashboard')
    //        }else if(!isAdminPage && !isPromoter) {
    //            next('/epanel/dashboard')
    //        }
    //    }
    next()
})

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}

export default router