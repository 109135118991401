import { getPayslips } from '@/api/mobile/payslip'

const state = {
	object: {}
}

const mutations = {
	SET_OBJECT: (state, data) => {
		state.object = data.object
	},

}

const actions = {
	getPayslips({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getPayslips(paramsData).then(response => {
				commit('SET_OBJECT', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}