import { getProjects, retrieveProject, createProject, updateProject, deleteProject, archiveProject,
         getProjectSKUs, createProjectSKU, updateProjectSKU, deleteProjectSKU, getProjectPromoters,
         getProjectResources, createProjectResource, updateProjectResource, deleteProjectResource,
         getProjectCampaigns, getProjectCampaign, createProjectCampaign, updateProjectCampaign, deleteProjectCampaign,
         getCampaignTimesheet, getCampaignTimesheetShift } from '@/api/epanel/project'

const state = {
	items: [],
	headers: [],
	pagination: {},
	project: {},
	campaign: {},
	sku: {},
	promoter: {},
	resource: {},
}

const mutations = {
	SET_STATE: (state, data) => {
		state.items = data.items
		state.headers = data.headers
		state.pagination = data.pagination
	},
    SET_PROJECT: (state, data) => {
        state.project = data
    },
    SET_CAMPAIGN: (state, data) => {
        state.campaign = data
    },
    SET_SKU: (state, data) => {
        state.sku = data
    },
    SET_PROMOTER: (state, data) => {
        state.promoter = data
    },
    SET_RESOURCE: (state, data) => {
        state.resource = data
    },
    SET_TIMESHEET: (state, data) => {
        state.items = data
    }
}

const actions = {
	getProjects({ commit }, data) {
		return new Promise((resolve, reject) => {
            getProjects(data).then(response => {
				commit('SET_PROJECT', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getProject({ commit }, data) {
        return new Promise((resolve, reject) => {
            retrieveProject(data.id, data.object).then(response => {
                commit('SET_PROJECT', response.data.object)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
	},
	createProject({ commit }, data) {
		return new Promise((resolve, reject) => {
            createProject(data).then(response => {
                commit('SET_PROJECT', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateProject({ commit }, data) {
		return new Promise((resolve, reject) => {
            updateProject(data.id, data.object).then(response => {
                commit('SET_PROJECT', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
    archiveProject({ commit }, data) {
        return new Promise((resolve, reject) => {
            archiveProject(data.id, data.object).then(response => {
                commit('SET_PROJECT', response.data.object)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
	deleteProject({ commit }, data) {
		return new Promise((resolve, reject) => {
            deleteProject(data.id).then(response => {
                commit('SET_PROJECT', {})
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
    getProjectResources({ commit }, data) {
		return new Promise((resolve, reject) => {
            getProjectResources(data).then(response => {
				commit('SET_RESOURCE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	createProjectResource({ commit }, data) {
		return new Promise((resolve, reject) => {
            createProjectResource(data).then(response => {
                commit('SET_RESOURCE', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateProjectResource({ commit }, data) {
		return new Promise((resolve, reject) => {
            updateProjectResource(data.id, data.object).then(response => {
                commit('SET_RESOURCE', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	deleteProjectResource({ commit }, data) {
		return new Promise((resolve, reject) => {
            deleteProjectResource(data.id, data.object).then(response => {
                commit('SET_RESOURCE', {})
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
    getProjectSKUs({ commit }, data) {
		return new Promise((resolve, reject) => {
            getProjectSKUs(data).then(response => {
				commit('SET_SKU', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	createProjectSKU({ commit }, data) {
		return new Promise((resolve, reject) => {
            createProjectSKU(data).then(response => {
                commit('SET_SKU', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateProjectSKU({ commit }, data) {
		return new Promise((resolve, reject) => {
            updateProjectSKU(data.id, data.object).then(response => {
                commit('SET_SKU', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	deleteProjectSKU({ commit }, data) {
		return new Promise((resolve, reject) => {
            deleteProjectSKU(data.id, data.object).then(response => {
                commit('SET_SKU', {})
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getProjectCampaigns({ commit }, data) {
		return new Promise((resolve, reject) => {
            getProjectCampaigns(data).then(response => {
				commit('SET_CAMPAIGN', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getProjectCampaign({ commit }, data) {
		return new Promise((resolve, reject) => {
            getProjectCampaign(data.id).then(response => {
				commit('SET_CAMPAIGN', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	createProjectCampaign({ commit }, data) {
		return new Promise((resolve, reject) => {
            createProjectCampaign(data).then(response => {
                commit('SET_CAMPAIGN', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateProjectCampaign({ commit }, data) {
		return new Promise((resolve, reject) => {
            updateProjectCampaign(data.id, data.object).then(response => {
                commit('SET_CAMPAIGN', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	deleteProjectCampaign({ commit }, data) {
		return new Promise((resolve, reject) => {
            deleteProjectCampaign(data.id, data.object).then(response => {
                commit('SET_CAMPAIGN', {})
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getProjectPromoters({ commit }, data) {
		return new Promise((resolve, reject) => {
            getProjectPromoters(data).then(response => {
				commit('SET_RESOURCE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getCampaignTimesheet({ commit }, data) {
		return new Promise((resolve, reject) => {
            getCampaignTimesheet(data.id, data.object).then(response => {
				commit('SET_TIMESHEET', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getCampaignTimesheetShift({ commit }, data) {
		return new Promise((resolve, reject) => {
            getCampaignTimesheetShift(data.ids).then(response => {
				commit('SET_TIMESHEET', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}