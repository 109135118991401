import { getPromoters, createPromoter, updatePromoterProfile, updatePromoterAddress, updatePromoterBank, updatePromoterStatus, getPromoter, retrievePromoter, getPromoterLogs } from '@/api/epanel/promoter'

const state = {
	items: [],
	headers: [],
	pagination: {},
	genders: [],
	citizenships: [],
	banks: [],
	promoter: {}
}

const mutations = {
	SET_STATE: (state, data) => {
		state.items = data.items
		state.headers = data.headers
		state.pagination = data.pagination
		state.genders = data.genders
		state.citizenships = data.citizenships
		state.banks = data.banks
	},
    SET_PROMOTER: (state, data) => {
        state.promoter = data
    }
}

const actions = {
	getPromoters({ commit }, data) {
		return new Promise((resolve, reject) => {
			getPromoters(data).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getPromoter({ commit }) {
        return new Promise((resolve, reject) => {
            getPromoter().then(response => {
                commit('SET_PROMOTER', response.data.object)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
	},
	createPromoter({ commit }, data) {
		return new Promise((resolve, reject) => {
            createPromoter(data).then(response => {
                commit('SET_PROMOTER', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updatePromoterProfile({ commit }, data) {
		return new Promise((resolve, reject) => {
            updatePromoterProfile(data.id, data.object).then(response => {
                commit('SET_PROMOTER', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updatePromoterStatus({ commit }, data) {
		return new Promise((resolve, reject) => {
            updatePromoterStatus(data.id, data.object).then(response => {
                commit('SET_PROMOTER', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
    updatePromoterAddress({ commit }, data) {
        return new Promise((resolve, reject) => {
            updatePromoterAddress(data.id, data.object).then(response => {
                commit('SET_PROMOTER', response.data.object)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    updatePromoterBank({ commit }, data) {
        return new Promise((resolve, reject) => {
            updatePromoterBank(data.id, data.object).then(response => {
                commit('SET_PROMOTER', response.data.object)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
	retrievePromoter({ commit }, data) {
		return new Promise((resolve, reject) => {
            retrievePromoter(data.id, data.object).then(response => {
                commit('SET_PROMOTER', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getPromoterLogs({ commit }, data) {
		return new Promise((resolve, reject) => {
			getPromoterLogs(data.id, data.object).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}