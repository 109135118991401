import { getAddressOutlets, getChainOutlets, createAddressOutlet, createChainOutlet, updateAddressOutlet, updateChainOutlet,
         deleteChainOutlet, deleteAddressOutlet } from '@/api/epanel/outlet'

const state = {
	items: [],
	headers: [],
	pagination: {},
	outlet: {}
}

const mutations = {
	SET_STATE: (state, data) => {
		state.items = data.items
		state.headers = data.headers
		state.pagination = data.pagination
	},
    SET_OUTLET: (state, data) => {
        state.outlet = data
    }
}

const actions = {
	getAddressOutlets({ commit }, data) {
		return new Promise((resolve, reject) => {
            getAddressOutlets(data.id, data.filters).then(response => {
				commit('SET_OUTLET', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getChainOutlets({ commit }, data) {
		return new Promise((resolve, reject) => {
            getChainOutlets(data.id, data.filters).then(response => {
				commit('SET_OUTLET', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	createAddressOutlet({ commit }, data) {
		return new Promise((resolve, reject) => {
            createAddressOutlet(data.id, data.object).then(response => {
                commit('SET_OUTLET', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	createChainOutlet({ commit }, data) {
		return new Promise((resolve, reject) => {
            createChainOutlet(data.id, data.object).then(response => {
                commit('SET_OUTLET', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateAddressOutlet({ commit }, data) {
		return new Promise((resolve, reject) => {
            updateAddressOutlet(data.ids, data.object).then(response => {
                commit('SET_OUTLET', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateChainOutlet({ commit }, data) {
		return new Promise((resolve, reject) => {
            updateChainOutlet(data.ids, data.object).then(response => {
                commit('SET_OUTLET', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
    deleteChainOutlet({ commit }, data) {
        return new Promise((resolve, reject) => {
            deleteChainOutlet(data.id).then(response => {
                commit('SET_OUTLET', {})
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    deleteAddressOutlet({ commit }, data) {
        return new Promise((resolve, reject) => {
            deleteAddressOutlet(data.id).then(response => {
                commit('SET_OUTLET', {})
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    }
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}