import { getShifts, getShiftActivity, getShiftSales, getShiftFeedbacks, updateShiftStatus, updateShiftSales, updateShiftSurvey, createShiftFeedback, deleteShiftFeedback, getShiftResources, getShiftAttendance, createShiftAttendance } from '@/api/mobile/shift'

const state = {
	object: {}
}

const mutations = {
	SET_OBJECT: (state, data) => {
		state.object = data.object
	},

}

const actions = {
	getShifts({ commit }, data) {
		return new Promise((resolve, reject) => {
			getShifts(data).then(response => {
				commit('SET_OBJECT', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getShiftActivity({ commit }, data) {
		return new Promise((resolve, reject) => {
			getShiftActivity(data).then(response => {
				commit('SET_OBJECT', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getShiftAttendance({ commit }, data) {
		return new Promise((resolve, reject) => {
			getShiftAttendance(data).then(response => {
				commit('SET_OBJECT', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	createShiftAttendance({ commit }, data) {
		return new Promise((resolve, reject) => {
			createShiftAttendance(data).then(response => {
				commit('SET_OBJECT', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateShiftStatus({ commit }, data) {
		return new Promise((resolve, reject) => {
			updateShiftStatus(data).then(response => {
				commit('SET_OBJECT', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getShiftSales({ commit }, data) {
		return new Promise((resolve, reject) => {
			getShiftSales(data).then(response => {
				commit('SET_OBJECT', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateShiftSales({ commit }, data) {
		return new Promise((resolve, reject) => {
			updateShiftSales(data).then(response => {
				commit('SET_OBJECT', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateShiftSurvey({ commit }, data) {
		return new Promise((resolve, reject) => {
			updateShiftSurvey(data).then(response => {
				commit('SET_OBJECT', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getShiftFeedbacks({ commit }, data) {
		return new Promise((resolve, reject) => {
			getShiftFeedbacks(data).then(response => {
				commit('SET_OBJECT', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	createShiftFeedback({ commit }, data) {
		return new Promise((resolve, reject) => {
			createShiftFeedback(data).then(response => {
				commit('SET_OBJECT', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	deleteShiftFeedback({ commit }, data) {
		return new Promise((resolve, reject) => {
			deleteShiftFeedback(data).then(response => {
				commit('SET_OBJECT', {})
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
    getShiftResources({ commit }, data) {
        return new Promise((resolve, reject) => {
            getShiftResources(data).then(response => {
                commit('SET_OBJECT', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}