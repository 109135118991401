import { getBanks, createBank, getBank, updateBank, deleteBank, getBankBranches, createBankBranch, updateBankBranch, deleteBankBranch } from '@/api/epanel/bank'

const state = {
	items: [],
	headers: [],
	pagination: {},
}

const mutations = {
	SET_STATE: (state, data) => {
		state.items = data.items
		state.headers = data.headers
		state.pagination = data.pagination
	},
}

const actions = {
	getBanks({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getBanks(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getBank({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getBank(paramsData.id).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	createBank({ commit }, data) {
		return new Promise((resolve, reject) => {
			createBank(data).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateBank({ commit }, data) {
		return new Promise((resolve, reject) => {
			updateBank(data.id, data.object).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	deleteBank({ commit }, data) {
		return new Promise((resolve, reject) => {
			deleteBank(data.id).then(response => {
				commit('SET_STATE', {})
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	getBankBranches({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getBankBranches(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	createBankBranch({ commit }, data) {
		return new Promise((resolve, reject) => {
			createBankBranch(data).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateBankBranch({ commit }, data) {
		return new Promise((resolve, reject) => {
			updateBankBranch(data.id, data.object).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	deleteBankBranch({ commit }, data) {
		return new Promise((resolve, reject) => {
			deleteBankBranch(data.id).then(response => {
				commit('SET_STATE', {})
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}