import { login, logout, updateProfile, updatePassword } from '@/api/auth'
import { encodeBase64 } from '@/utils/encodes'
import { removeSessionStorage, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'

const state = {
	full_name: '',
	first_name: '',
	last_name: '',
	username: '',
	email: '',
	role: '',
	access_token: ''
}

const mutations = {
	SET_TOKEN: (state, token) => {
		state.access_token = token
	},
	SET_USER: (state, user) => {
        state.username = user.username
		state.full_name = user.full_name
		state.first_name = user.first_name
		state.last_name = user.last_name
		state.email = user.email
		state.role = user.role
		state.access_token = user.access_token
	},
	REMOVE_USER: (state, value) => {
		state.username = value
		state.full_name = value
        state.first_name = value
		state.last_name = value
		state.email = value
		state.role = value
		state.access_token = value
	}
}

const actions = {
	login({ commit }, loginData) {
		const { username, password, source } = loginData
		return new Promise((resolve, reject) => {
            login({ username: encodeBase64(username), password: encodeBase64(password), source: encodeBase64(source) }).then(response => {
				commit('SET_USER', response.data.object)
				setToken(response.data.object.access_token)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	logout({ commit }) {
		return new Promise((resolve, reject) => {
			logout().then(() => {
				commit('REMOVE_USER', '')
				removeSessionStorage('blink-data')
				removeToken()
				resetRouter()
				resolve()
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateProfile({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateProfile(data).then(response => {
                console.log('commit = ', commit)
                commit('SET_USER', response.data.object)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
	},
	updatePassword({ commit }, data) {
        const { password, confirm_password } = data
            return new Promise((resolve, reject) => {
                updatePassword({ password: encodeBase64(password), confirm_password: encodeBase64(confirm_password) }).then(response => {
                commit('SET_USER', response.data.object)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}