import { get, post, put, remove } from '@/utils/request'

export function getCompanies(data) {
	return get('api/epanel/companies/', data)
}

export function getCompany(id) {
	return get('api/epanel/companies/' + id + '/')
}

export function createCompany(data) {
    return post('api/epanel/companies/', data)
}

export function updateCompany(id, data) {
    return put('api/epanel/companies/' + id + '/', data)
}

export function deleteCompany(id) {
    return remove('api/epanel/companies/' + id + '/')
}

export function getCompanyBanks(data) {
	return get('api/epanel/companies/banks/', data)
}

export function createCompanyBank(data) {
    return post('api/epanel/companies/banks/', data)
}

export function updateCompanyBank(id, data) {
    return put('api/epanel/companies/banks/' + id + '/', data)
}

export function deleteCompanyBank(id, data) {
    return remove('api/epanel/companies/banks/' + id + '/', data)
}
