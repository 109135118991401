import { getChains, createChain, updateChain, deleteChain } from '@/api/epanel/chain'

const state = {
	items: [],
	headers: [],
	pagination: {},
	chain: {}
}

const mutations = {
	SET_STATE: (state, data) => {
		state.items = data.items
		state.headers = data.headers
		state.pagination = data.pagination
	},
    SET_CHAIN: (state, data) => {
        state.chain = data
    }
}

const actions = {
	getChains({ commit }, paramsData) {
		return new Promise((resolve, reject) => {
			getChains(paramsData).then(response => {
				commit('SET_STATE', response.data)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	createChain({ commit }, data) {
		return new Promise((resolve, reject) => {
            createChain(data).then(response => {
                commit('SET_CHAIN', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateChain({ commit }, data) {
		return new Promise((resolve, reject) => {
            updateChain(data.id, data.object).then(response => {
                commit('SET_CHAIN', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
	deleteChain({ commit }, data) {
		return new Promise((resolve, reject) => {
            deleteChain(data.id).then(response => {
                commit('SET_CHAIN', response.data.object)
                resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}