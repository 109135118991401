import { get, post } from '@/utils/request'

export function getRoles(params) {
	return get('api/epanel/account/roles/', params)
}

export function getRolePermissions(id, params) {
    return get('api/epanel/account/roles/' + id + '/permissions/', params)
}

export function updateRolePermissions(id, data) {
    return post('api/epanel/account/roles/' + id + '/permissions/', data)
}

