import { get, post, put } from '@/utils/request'

export function getAccount(params) {
	return get('api/mobile/account/', params)
}

export function checkAccount(data) {
    return post('api/mobile/account/check/', data)
}

export function createAccount(data) {
    return post('api/mobile/account/', data)
}

export function getProfile(){
    return get('api/mobile/account/profile/')
}

export function updateProfile(url, data) {
    return put(url, data)
}

export function createProfilePicture(data) {
    return post('api/mobile/account/avatar/', data)
}
